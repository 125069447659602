var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.mounted
    ? _c(
        "div",
        [
          _c(
            "CCard",
            [
              _c(
                "CCardBody",
                [
                  _c("div", [
                    _c("h4", { staticClass: "card-title mb-0" }, [
                      _vm._v("Customer Order Analysis"),
                    ]),
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v(
                        "Detailed customer ordering patterns and behaviors"
                      ),
                    ]),
                  ]),
                  _c(
                    "CCard",
                    {
                      staticClass: "filters my-3",
                      attrs: { "accent-color": "warning" },
                    },
                    [
                      _c("CCardBody", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid" },
                          [
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                options: _vm.years,
                                searchable: false,
                                placeholder: "Select year...",
                              },
                              on: { input: _vm.inputFilter },
                              model: {
                                value: _vm.filters.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "year", $$v)
                                },
                                expression: "filters.year",
                              },
                            }),
                            _c("v-select", {
                              staticClass: "v-select-filter",
                              attrs: {
                                options: _vm.orderChannels,
                                searchable: false,
                                placeholder: "Select order channel...",
                              },
                              on: { input: _vm.inputFilter },
                              model: {
                                value: _vm.filters.orderChannel,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "orderChannel", $$v)
                                },
                                expression: "filters.orderChannel",
                              },
                            }),
                            _c("CInput", {
                              staticClass: "mb-0 search",
                              attrs: {
                                type: "search",
                                placeholder: "Search for customer..",
                              },
                              on: { input: _vm.searchFilter },
                              model: {
                                value: _vm.filters.search,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filters, "search", $$v)
                                },
                                expression: "filters.search",
                              },
                            }),
                            _c("div", { staticClass: "form-check mt-2" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.multipleOrders,
                                    expression: "filters.multipleOrders",
                                  },
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "multipleOrders",
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.filters.multipleOrders
                                  )
                                    ? _vm._i(_vm.filters.multipleOrders, null) >
                                      -1
                                    : _vm.filters.multipleOrders,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$a = _vm.filters.multipleOrders,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.filters,
                                              "multipleOrders",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.filters,
                                              "multipleOrders",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.filters,
                                          "multipleOrders",
                                          $$c
                                        )
                                      }
                                    },
                                    _vm.inputFilter,
                                  ],
                                },
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "multipleOrders" },
                                },
                                [
                                  _vm._v(
                                    " Only multiple orders (per customer) "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "row mb-4 stats-cards" }, [
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "primary",
                            header: `${_vm.stats.totalCustomers}`,
                            text: "Total Customers",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "info",
                            header: `${_vm.stats.totalOrders}`,
                            text: "Total Orders",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "warning",
                            header: _vm.$options.filters.toCurrency(
                              _vm.stats.avgOrderAmount,
                              "remove_thousand_separator"
                            ),
                            text: "Average Order Amount",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-sm-6 col-lg-3" },
                      [
                        _c("CWidgetDropdown", {
                          attrs: {
                            color: "success",
                            header: `${_vm.stats.avgOrderCount}`,
                            text: "Orders per Customer",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "table-responsive" },
                    [
                      _c("CDataTable", {
                        attrs: {
                          striped: "",
                          hover: "",
                          items: _vm.customers,
                          fields: [
                            {
                              key: "customerName",
                              label: "Customer Name",
                              _style: "min-width:200px",
                            },
                            {
                              key: "customerEmail",
                              label: "Email",
                              _style: "min-width:250px",
                            },
                            { key: "year", label: "Year" },
                            { key: "orderCount", label: "Orders" },
                            { key: "averageAmount", label: "Avg Amount (€)" },
                            { key: "actions", label: "", _style: "width:1%" },
                          ],
                          sorter: { external: true, resetable: true },
                          itemsPerPageSelect: {
                            external: true,
                            values: [5, 15, 25, 50, 100, 250, 500],
                          },
                          "items-per-page": _vm.itemsPerPage,
                          loading: _vm.loading,
                          noItemsView: {
                            noResults: "No filtering results are available!",
                            noItems: "No items found!",
                          },
                        },
                        on: {
                          "update:itemsPerPage": function ($event) {
                            _vm.itemsPerPage = $event
                          },
                          "update:items-per-page": function ($event) {
                            _vm.itemsPerPage = $event
                          },
                          "update:sorter-value": _vm.sorterValue,
                          "pagination-change": _vm.paginationChange,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "year",
                              fn: function ({ item }) {
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(item.year || "All Years") +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "averageAmount",
                              fn: function ({ item }) {
                                return [
                                  _c("td", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            item.averageAmount,
                                            "remove_thousand_separator"
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              },
                            },
                            {
                              key: "actions",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "CButton",
                                        {
                                          attrs: {
                                            color: "info",
                                            size: "sm",
                                            shape: "pill",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showCustomerDetails(
                                                item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-zoom-in" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3944417658
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "CCard",
                    { staticClass: "actions sticky-bottom" },
                    [
                      _c("CCardBody", { staticClass: "p-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "d-flex flex-wrap align-items-center",
                            staticStyle: { gap: "0.75rem" },
                          },
                          [
                            _c("div", [
                              _c("h5", { staticClass: "mt-1" }, [
                                _vm._v("Total: "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-inline count bg-primary pb-1",
                                  },
                                  [_vm._v(_vm._s(_vm.total))]
                                ),
                              ]),
                            ]),
                            _vm.pages > 1
                              ? _c(
                                  "div",
                                  { staticClass: "ml-auto" },
                                  [
                                    _c("CPagination", {
                                      attrs: {
                                        align: "center",
                                        dots: false,
                                        pages: _vm.pages,
                                        "active-page": _vm.activePage,
                                      },
                                      on: {
                                        "update:activePage": function ($event) {
                                          _vm.activePage = $event
                                        },
                                        "update:active-page": function (
                                          $event
                                        ) {
                                          _vm.activePage = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "CModal",
                    {
                      attrs: {
                        show: _vm.showModal,
                        title: "Customer Order Details",
                        size: "lg",
                      },
                      on: {
                        "update:show": function ($event) {
                          _vm.showModal = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c(
                                  "CButton",
                                  {
                                    attrs: { color: "secondary" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showModal = false
                                      },
                                    },
                                  },
                                  [_vm._v(" Close ")]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3183794055
                      ),
                    },
                    [
                      _vm.selectedCustomer
                        ? _c("div", [
                            _c("div", { staticClass: "customer-info mb-3" }, [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(_vm.selectedCustomer.customerName)
                                ),
                              ]),
                              _c("p", { staticClass: "text-muted" }, [
                                _vm._v(
                                  _vm._s(_vm.selectedCustomer.customerEmail)
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "row mb-3" }, [
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border rounded p-3 text-center",
                                  },
                                  [
                                    _c("h6", [_vm._v("Total Orders")]),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.selectedCustomer.orderCount)
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border rounded p-3 text-center",
                                  },
                                  [
                                    _c("h6", [_vm._v("Average Amount")]),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("toCurrency")(
                                            _vm.selectedCustomer.averageAmount,
                                            "remove_thousand_separator"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                              _c("div", { staticClass: "col-md-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border rounded p-3 text-center",
                                  },
                                  [
                                    _c("h6", [_vm._v("Year")]),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.selectedCustomer.year ||
                                            "All Years"
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _c("mc-spinner", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    attrs: {
                      opacity: 0.8,
                      mtop: 32 + _vm.mcSpinnerMarginTop + "px",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _c("CSpinner", { attrs: { color: "primary" } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }